import { griddoDamDefaults } from "@config";
import {
	SiteProvider as GriddoSiteProvider,
	SiteProviderProps as GriddoSiteProviderProps,
} from "@griddo/core";
import { translations } from "@schemas/i18n";
import { Subthemes, Themes } from "@themes-setup";
import * as React from "react";

// Custom CSS
import "@themes/_custom/global.css";
import "@themes/_custom/normalize.css";
import "@themes/_custom/text-styles.css";
import "@themes/_custom/utils.css";
import "@themes/_custom/swiper.css";

// Themes
import "@themes/_css/global-theme.css";
import "@themes/_css/marketing-theme.css";

interface SiteProviderProps extends GriddoSiteProviderProps {
	theme: Themes;
	subtheme: Subthemes;
}

/**
 * `<SiteProvider>`
 *
 * This component is a wrapper to the Griddo <SiteProvider>. Must be exported and used to wrap the app/web.
 * This is a great place to add contexts like theme providers etc.
 */
function SiteProvider(props: SiteProviderProps) {
	const { children, ...rest } = props;
	const isEditor = rest.renderer === "editor" || rest.renderer === "preview";

	return (
		<GriddoSiteProvider
			griddoDamDefaults={griddoDamDefaults}
			translations={translations}
			{...rest}
		>
			<div
				id="___griddo"
				// Set the site theme
				data-theme={rest.theme}
				// Set the default subtheme
				data-subtheme={rest.subtheme || "default"}
				// Allows scroll in the Griddo editor preview area
				style={isEditor ? { height: "100%" } : {}}
			>
				<React.Suspense fallback={null}>{children}</React.Suspense>
			</div>
		</GriddoSiteProvider>
	);
}

export { SiteProvider };
