// Gatsby Browser API

// Webfont css delay.
//
// When the browser load the page, this `onClientEntry` set the stylesheet rel
// attribute to "active" the webfonts css.
// This way when the browser load the html page, webfonst doesn't block the
// render.

const onClientEntry = () => {
	const griddoWebfonts = document.getElementById("griddo-webfonts");

	if (griddoWebfonts) {
		griddoWebfonts.setAttribute("rel", "stylesheet");
	}
};

export default {
	onClientEntry,
};
