import { Schema } from "@griddo/core";

// Lets TypeScript do them type inferring for the `themes` to extract theme
// values as union type. Otherwise is kind of impossible due to the explicit
// Type `Schema.Themes`
const themes = [
	{
		default: true,
		label: "Marketing theme",
		value: "marketing-theme",
	},
] as const;

const schema: Readonly<Schema.Themes> = themes;

export { themes };
export default schema;
